<template>
  <a-row>
    <a-col :span="12" class="p-1">
      <div class="h-96 w-full bg-gray-100 rounded p-1" ref="chartProductDownloadCount"></div>
    </a-col>
    <a-col :span="12" class="p-1">
      <div class="h-96 w-full bg-gray-100 p-1 rounded" ref="chartProductDownloadRegion"></div>
    </a-col>
  </a-row>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import * as echarts from 'echarts';
import AdminApiClient from '@/utils/AdminApiClient';
import dayjs from 'dayjs';
/** @var {HtmlElement} */
const chartProductDownloadCount = ref(null);
/** @var {HtmlElement} */
const chartProductDownloadRegion = ref(null);
// on mounted
onMounted(mounted);

// on mounted
async function mounted() {
    let xAxisData = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];
    let thisMonthValues = await getValue('product.download.count.this-month.total.list');
    let thisMonthSeriesData = thisMonthValues.map( item => item.count );
    let lastMonthKey = dayjs().subtract(1, 'month').format('YYYY-MM');
    let lastMonthValues = await getValue('product.download.count.month.total.list', lastMonthKey);
    let lastMonthSeriesData = lastMonthValues.map( item => item.count );
    let chart = echarts.init(chartProductDownloadCount.value);
    chart.setOption({
        xAxis: {type: 'category',data: xAxisData},
        yAxis: {type: 'value'},
        series: [
            {data: thisMonthSeriesData, type:'bar', name:'本月',label: {show: true,position: 'top'}},
            {data: lastMonthSeriesData, type:'bar', name:'上月', itemStyle:{color:'#e6e5e5'}},
        ],
        tooltip: {trigger: 'axis',axisPointer: {type: 'shadow'}},
        grid: {left:10,right: 10,bottom: 10,containLabel: true},
        title: {text: '下载量'},
    });

    let regionValues = await getValue('product.download.region.this-month');
    regionValues.sort( (a,b) => b.count - a.count );
    let serieData = regionValues.map( item => ({value: item.count, name: `${item.region} (${item.count})`}) );
    let chartRegion = echarts.init(chartProductDownloadRegion.value);
    chartRegion.setOption({
        title: {text: '下载地区'},
        grid: {left:10,right: 10,bottom: 10,containLabel: true},
        tooltip: {trigger: 'item'},
        series: [{type: 'pie',radius: '70%',data: serieData}],
    });
}

// get value
async function getValue( type, key='' ) {
    let api = AdminApiClient.getClicent();
    return await api.call('statistics/get-content', {type,key});
}
</script>