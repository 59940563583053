<template>
  <a-row>
    <a-col :span="4" class="p-1">
      <div class="bg-red-400 p-5 rounded">
        <h2 class="text-slate-50">
          今日下载量：<br/>
          <span class="text-6xl">{{ statis['product.download.count.today.total']?.count || '--' }}</span>
        </h2>
      </div>
    </a-col>

      <a-col :span="4" class="p-1">
        <div class="bg-green-400">ssss</div>
      </a-col>
      <a-col :span="4" class="p-1">
        <div class="bg-blue-400">ssss</div>
      </a-col>
      <a-col :span="4" class="p-1">
        <div class="bg-yellow-400">ssss</div>
      </a-col>
      <a-col :span="4" class="p-1">
        <div class="bg-pink-400">ssss</div>
      </a-col>
      <a-col :span="4" class="p-1">
        <div class="bg-slate-400">ssss</div>
      </a-col>
  </a-row>

  
</template>
<script setup>
import AdminApiClient from '@/utils/AdminApiClient';
import { onMounted, ref } from 'vue'
/** @var {Object} */
const statis = ref({});
// on mounted
onMounted(mounted);

// on mounted
async function mounted() {
    statis.value = {};
    statis.value['product.download.count.today.total'] = await getValue('product.download.count.today.total');
}

// get value
async function getValue( type, key='' ) {
    let api = AdminApiClient.getClicent();
    return await api.call('statistics/get-content', {type,key});
}
</script>